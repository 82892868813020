import HTTPWrapper from './HTTPWrapper'
import { API_ENDPOINT, API_SERVICES } from '@/constans'
import { ApiEventDatesResponse } from './types'

export type CourseEventServiceCallback = (
  queryParams?: Record<string, any>
) => Promise<Record<string, any> | Array<Record<string, any>>>

export type CourseEventDatesServiceCallback = (queryParams?: Record<string, any>) => Promise<ApiEventDatesResponse>

class CourseEventService {
  getCourseEventCalendar: CourseEventServiceCallback = async (queryParams = {}) =>
    await HTTPWrapper.get({ path: `${API_ENDPOINT}${API_SERVICES.getEventsCalendar}`, queryParams })

  getAvailableMonths: CourseEventDatesServiceCallback = async (queryParams = {}) =>
    (await HTTPWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getAvailableMonths}`,
      queryParams
    })) as ApiEventDatesResponse
}

export const courseEventService = new CourseEventService()
