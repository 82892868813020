import { API_ENDPOINT, API_SERVICES } from '@/constans'
import HTTPWrapper from './HTTPWrapper'
import { ApiAllConferenceEventResponse, ApiSingleConferenceEventResponse } from './types'

export type ConferenceEventServiceCallback = (id: string) => Promise<ApiSingleConferenceEventResponse>
export type AllConferenceEventServiceCallback = (
  queryParams?: Record<string, string | number[] | string[]>
) => Promise<ApiAllConferenceEventResponse>

class ConferenceEventService {
  getAllConferenceEvents: AllConferenceEventServiceCallback = async (queryParams = {}) =>
    (await HTTPWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getAllConferenceEvents}`,
      queryParams
    })) as ApiAllConferenceEventResponse

  getConferenceEvent: ConferenceEventServiceCallback = async id =>
    (await HTTPWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getConferenceEvent.replace('{id}', id)}`
    })) as ApiSingleConferenceEventResponse
}

export const conferenceEventService = new ConferenceEventService()
