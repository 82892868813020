import { API_ENDPOINT, API_SERVICES } from '@/constans'
import HTTPWrapper from './HTTPWrapper'
import { ApiGalleryDocumentResponse } from './types'

export type GalleryServiceCallback = (slug: string) => Promise<ApiGalleryDocumentResponse>

class GalleryService {
  getDocument: GalleryServiceCallback = async code =>
    (await HTTPWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getGalleryDocument.replace('{code}', code)}`
    })) as ApiGalleryDocumentResponse
}

export const galleryService = new GalleryService()
