import HTTPWrapper from './HTTPWrapper'
import { API_ENDPOINT, API_SERVICES } from '@/constans'

export type EmploymentCallback = (body: Record<string, any>) => Promise<Record<string, any> | undefined>

class Employment {
  createPostulation: EmploymentCallback = async (body: Record<string, any>) =>
    await HTTPWrapper.post({
      path: `${API_ENDPOINT}${API_SERVICES.createPostulation}`,
      body
    })
}

export default new Employment()
