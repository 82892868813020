import HTTPWrapper from './HTTPWrapper'
import { API_ENDPOINT, API_SERVICES } from '@/constans'

export type createVisitCallback = (body: Record<string, any>) => Promise<Record<string, any> | undefined>

class VisitService {
  registerVisit: createVisitCallback = async (body: Record<string, any>) =>
    await HTTPWrapper.post({
      path: `${API_ENDPOINT}${API_SERVICES.createVisit}`,
      body
    })
}

export const visitService = new VisitService()
