import { ReactNode } from 'react'
import style from './FullImageBanner.module.css'
import { classNames } from '@/components/shared/classNames'
import Image from 'next/image'

interface FullImageBannerProps {
  imageUrl: string
  imageMobileUrl?: string
  imageClassName?: string
  backgroundOpacity?: string
  backgroundPosition?: string | undefined
  bannerHeight: 'small' | 'medium' | 'large'
  label?: ReactNode
  title?: ReactNode
  subtitle?: ReactNode
  description?: ReactNode
  actionsSlot?: ReactNode
  contentAligment: 'left' | 'center' | 'right'
  contentClassNames: string
  className?: string
  topPennant?: ReactNode
}

const FullImageBanner = ({
  imageUrl,
  imageMobileUrl = '',
  imageClassName = '',
  backgroundPosition,
  bannerHeight,
  label,
  title,
  subtitle,
  description,
  actionsSlot,
  contentAligment,
  contentClassNames,
  backgroundOpacity = 'rgba(0, 0, 0, 0.25)',
  className = '',
  topPennant
}: FullImageBannerProps) => {
  const imagePositionClass = backgroundPosition ?? 'object-center'
  return (
    <div className={classNames(className, 'relative z-0', style[bannerHeight])}>
      <div className={classNames('absolute z-0 w-full h-full')}>
        <Image
          src={imageUrl}
          alt="banner"
          layout="fill"
          objectFit="cover"
          loading="eager"
          className={classNames('hidden sm:block', style.imageBanner, style[bannerHeight], imagePositionClass)}
        />
        {
          /* Mobile */
          imageMobileUrl === '' ? null : (
            <Image
              src={imageMobileUrl}
              alt="banner"
              layout="fill"
              objectFit="cover"
              loading="eager"
              className={classNames('block sm:hidden', style.imageBanner, style[bannerHeight], imagePositionClass)}
            />
          )
        }
      </div>
      <div className={classNames('relative z-10', style[bannerHeight], imageClassName)}>
        {topPennant && <div className="topPennant">{topPennant}</div>}
        <div
          className={classNames(style.imageBannerLayer, style[bannerHeight])}
          style={{ background: backgroundOpacity, backgroundBlendMode: 'multiply' }}
        >
          <div className={classNames(style.container, style.imageBannerContent, style[contentAligment])}>
            {label && <p className={classNames(contentClassNames, `font-black big-text mb1`)}>{label}</p>}
            {title && <div className={classNames(contentClassNames, `font-black big-title mb1`)}>{title}</div>}
            {subtitle && (
              <div className={classNames(contentClassNames, `child-span-blue-light small-title mb2`)}>{subtitle}</div>
            )}
            {description && (
              <div className={classNames(contentClassNames, `child-span-blue-light small-title mb2`)}>
                {description}
              </div>
            )}
            {actionsSlot}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullImageBanner
