import HTTPWrapper from './HTTPWrapper'
import { API_ENDPOINT, API_SERVICES } from '@/constans'

export type LeadServiceCallback = (body: Record<string, any>) => Promise<Record<string, any> | undefined>

class LeadService {
  createCourseLead: LeadServiceCallback = async (body: Record<string, any>) =>
    await HTTPWrapper.post({ path: `${API_ENDPOINT}${API_SERVICES.createCourseLead}`, body })

  createRequestInfoLead: LeadServiceCallback = async (body: Record<string, any>) =>
    await HTTPWrapper.post({ path: `${API_ENDPOINT}${API_SERVICES.createRequestInfoLead}`, body })

  createConsultancyLead: LeadServiceCallback = async (body: Record<string, any>) =>
    await HTTPWrapper.post({ path: `${API_ENDPOINT}${API_SERVICES.createConsultancyLead}`, body })

  createSegobLead: LeadServiceCallback = async (body: Record<string, any>) =>
    await HTTPWrapper.post({ path: `${API_ENDPOINT}${API_SERVICES.createSegobLead}`, body })
}

export const leadService = new LeadService()
