import styles from './Col.module.scss'
import { classNames } from '@/components/shared/classNames'

export interface ColProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}

export const Col = ({ children = null, className, onClick, ...props }: ColProps) => {
  return (
    <div className={classNames('grow', styles.col, className)} onClick={onClick} {...props}>
      {children}
    </div>
  )
}
