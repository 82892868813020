import { API_ENDPOINT, API_SERVICES } from '@/constans'
import HTTPWrapper, { httpServerWrapper } from './HTTPWrapper'
import {
  ApiCourseCategoryResponse,
  ApiCourseCertifyingEntityResponse,
  ApiCourseEventsCalendarResponse,
  ApiEventAllCoursesResponse,
  ApiEventSingleCourseResponse
} from './types'

export interface CourseBasicInfo {
  id: number
  name: string
  slug: string
}
export interface CourseCategory {
  id: number
  name: string
  icon_name: string
  courses: CourseBasicInfo[]
}

export type CourseServiceCallback = (slug: string, cache: RequestCache) => Promise<ApiEventSingleCourseResponse>
export type AllCoursesServiceCallback = (
  queryParams?: Record<string, string | number | number[] | string[]>
) => Promise<ApiEventAllCoursesResponse>
export type CourseCategoryServiceCallback = (
  queryParams?: Record<string, any>,
  cache?: RequestCache
) => Promise<ApiCourseCategoryResponse>
export type CourseCertifyingEntityServiceCallback = (
  queryParams?: Record<string, any>,
  cache?: RequestCache
) => Promise<ApiCourseCertifyingEntityResponse>
export type CourseEventsCalendarServiceCallback = (
  slug: string,
  cache?: RequestCache
) => Promise<ApiCourseEventsCalendarResponse>

class CourseService {
  getAllCourses: AllCoursesServiceCallback = async (queryParams = {}) =>
    (await httpServerWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getAllCourses}`,
      queryParams
    })) as ApiEventAllCoursesResponse

  getCourse: CourseServiceCallback = async (slug, cache = 'default') =>
    (await HTTPWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getCourse.replace('{slug}', slug)}`,
      cache
    })) as ApiEventSingleCourseResponse

  getCourseCategory: CourseCategoryServiceCallback = async (queryParams = {}, cache = 'default') =>
    (await httpServerWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getCourseCategory}`,
      queryParams,
      cache
    })) as ApiCourseCategoryResponse

  getCourseCertifyingEntity: CourseCertifyingEntityServiceCallback = async (queryParams = {}, cache = 'default') =>
    (await httpServerWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getCertifyingEntity}`,
      queryParams,
      cache
    })) as ApiCourseCertifyingEntityResponse

  getCourseEventsCalendar: CourseEventsCalendarServiceCallback = async (slug, cache = 'default') =>
    (await HTTPWrapper.get({
      path: `${API_ENDPOINT}${API_SERVICES.getCourseEventsCalendar.replace('{slug}', slug)}`,
      cache
    })) as ApiCourseEventsCalendarResponse
}

export const courseService = new CourseService()
