'use client'
import { errorContactForm } from '@/components/alerts/AlertConfigurations'
import Title from '@/components/atoms/Title'
import { showUIAlert, useGetUtmQueryParams, useSubmitForm } from '@/components/forms/FormUtils'
import FormWrapper from '@/components/forms/FormWrapper'
import { RequestedFormsConfigType, useMergeFormsConfig } from '@/components/forms/InputConfigs'
import Loader from '@/components/loader/Loader'
import BaseFormStyled from '@/components/simplified-form/BaseFormStyled'
import FormBody from '@/components/web-forms/components/FormBody'
import { leadService } from '@/services'
import { useRouter } from 'next/navigation'

interface Props {
  className?: string
}

const PagesContactForm = ({ className = '' }: Props) => {
  const navigate = useRouter()
  const baseFormInputConfigurations: RequestedFormsConfigType = [
    'name',
    'last_name',
    'company',
    'position',
    'phone',
    'email',
    'message',
    'accept_terms'
  ]
  const inputConfiguration = useMergeFormsConfig(baseFormInputConfigurations)
  const aditionalPayloadUtmData = useGetUtmQueryParams()

  const { handleSubmit, isLoading } = useSubmitForm({
    service: leadService.createRequestInfoLead,
    aditionalPayloadData: aditionalPayloadUtmData,
    onErrorCallback: () => {
      showUIAlert(errorContactForm)
    },
    onSuccessCallback: () => {
      navigate.push('/gracias')
    }
  })

  const message = isLoading ? 'Enviando...' : 'Enviar'
  return (
    <FormWrapper className={className}>
      {isLoading && <Loader />}
      <Title tag="h2" className="text-center">
        ¡Contáctenos!
      </Title>
      <FormBody>
        <BaseFormStyled
          InputsConfig={inputConfiguration}
          onSubmit={handleSubmit}
          submitText={message}
          submitClassName="text-lg py-2"
          uuid="contact-form"
          enableRecaptcha
        />
      </FormBody>
    </FormWrapper>
  )
}

export default PagesContactForm
